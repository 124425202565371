import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'src/components/GlobalStyles'
import ScrollToTopOnRouteChange from 'src/components/ScrollToTopOnRouteChange'
import AuthGate from 'src/components/AuthGate'
import Mq from 'src/components/Mq'
import AppLoading from 'src/components/AppLoading'
import Sandbox from 'src/components/Sandbox'
import { WeatherProvider } from 'src/components/Weather'
import theme from 'shared/theme'
import { ApolloProvider } from '@apollo/client'
import client from 'src/lib/apollo'
import AppWrapper from 'src/components/AppWrapper'
import { SearchProvider } from 'src/components/Search'
import { ResourceProvider } from 'src/components/Resource'
import MuiBaseTheme from 'src/components/MuiBaseTheme'
import GAPageView from 'src/components/GAPageView'
import TagManager from 'react-gtm-module'


/* Routes */
import SignInRoute from 'src/routes/sign-in'
import { Tag } from '@mui/icons-material'

try {
	if (location.hash) {
		const hash = new URLSearchParams(location.hash.slice(1))
		const idToken = hash.get('id_token')
		if (idToken.length > 0) {
			window.__ID_TOKEN = idToken
		}
	}
} catch (err) {}

const breakpoints = {
	small: 'screen and (max-width: 767px)',
	medium: 'screen and (min-width: 768px) and (max-width: 959px)',
	large: 'screen and (min-width: 960px)'
}

const App = () => {
	const tagManagerArgs = {
		gtmId: 'GTM-W4B8NSHJ'
	}
	
	TagManager.initialize(tagManagerArgs);

	return (
		<ApolloProvider client={client}>
			<WeatherProvider>
				<MuiBaseTheme>
					<ThemeProvider theme={theme}>
						<GlobalStyles />
						<Mq.Provider config={breakpoints}>
							<BrowserRouter>
								<GAPageView />
								<ScrollToTopOnRouteChange />
								<AuthGate>
									{({ isLoading, user }) => {
										if (isLoading) {
											return <AppLoading />
										}
										return user ? (
											<SearchProvider>
												<ResourceProvider>
													<AppWrapper />
												</ResourceProvider>
											</SearchProvider>
										) : (
											<Routes>
												<Route path="*" element={<Navigate to="/sign-in" />} />
												<Route path="/sign-in" element={<SignInRoute />} />
												<Route path="/sandbox" element={<Sandbox />} />
											</Routes>
										)
									}}
								</AuthGate>
							</BrowserRouter>
						</Mq.Provider>
					</ThemeProvider>
				</MuiBaseTheme>
			</WeatherProvider>
		</ApolloProvider>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))
