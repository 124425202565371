import React, { useState, useEffect } from 'react';
import ITHelpButton from './it-help.png'
import TagManager from 'react-gtm-module';

const ITHelp: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const onButtonClick = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-W4B8NSHJ'
      }
      
    TagManager.initialize(tagManagerArgs);
    
    TagManager.dataLayer({
      dataLayer: {
        event: 'it_help_button_click',
        category: 'IT Help',
        action: 'click',
        label: 'IT Help Button'
      }
    });
     window.open('https://ithelp.nibbi.com', '_blank');
  };

  const handleScroll = () => {
    if (window.scrollY > 300) setIsScrolled(true);
    else setIsScrolled(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        <button onClick={onButtonClick} className="rounded-full shadow-lg" style={{backgroundColor:'transparent',border:'none'}}>
          <img src={ITHelpButton} alt="IT Help" style={{borderRadius: '50%'}} width={75} height={75} />
        </button>
    </div>
  );
};

export default ITHelp;